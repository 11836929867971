/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_nav {
    position: fixed;
    width: 100%;
    height: 8vh;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
    margin: 0;
    top: 0;
    left: 0;
  }

  .nav_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .div_nav_home {
    height: 100%;
    width: fit-content;
    margin-right: 5vh;
  }
  .div_nav_home img {
    margin: 5%;
    height: 6vh;
    width: 23vh;
  }

  #div_nav_menu_icon {
    margin: 1.5vh;
    height: 5vh;
    width: 5vh;
  }

  #div_nav_menu_icon:hover {
    cursor: pointer;
  }

  .div_nav_menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 30vh;
    height: 92vh;
    box-shadow: 2px 2px 2px -1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: 0;
    left: -100%;
  }

  .div_nav_menu.active {
    left: 0;
  }

  .div_nav_service_div,
  .div_nav_contacts_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .div_nav_service_div.active h2,
  .div_nav_service_div.active svg,
  .div_nav_contacts_div.active h2,
  .div_nav_contacts_div.active svg {
    color: #ac9140;
  }

  .div_nav_service_div svg,
  .div_nav_contacts_div svg {
    font-size: 3vh;
    margin-top: auto;
    margin-bottom: auto;
  }

  .div_nav_menu h2 {
    height: fit-content;
    margin-left: 3vh;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1vh;
    font-size: 3vh;
    color: rgb(0, 0, 0);
  }

  .div_nav_menu h3 {
    font-size: 2vh;
    margin: 0px;
    padding: 5px 5px 5px 3vh;
  }

  .div_nav_service_items {
    border-bottom: 1px black solid;
  }

  .div_nav_service_items,
  .div_nav_contacts_items {
    background-color: #8a7435;
    color: white;
  }

  .div_nav_menu div:hover {
    cursor: pointer;
  }

  .div_nav_service_items h3:hover,
  .div_nav_contacts_items h3:hover {
    cursor: pointer;
  }

  /* On screens that are 1200px or more*/
  @media screen and (min-width: 1200px) {
    .div_nav_mission:hover,
    .div_nav_news:hover,
    .div_nav_choir:hover,
    .div_nav_youth:hover,
    .div_nav_donation:hover {
      background-color: #ac9140;
      cursor: pointer;
    }

    .div_nav_service:hover h2,
    .div_nav_service:hover svg,
    .div_nav_contacts:hover h2,
    .div_nav_contacts:hover svg {
      cursor: pointer;
      color: #ac9140;
    }

    .div_nav_mission:hover h2,
    .div_nav_news:hover h2,
    .div_nav_choir:hover h2,
    .div_nav_youth:hover h2,
    .div_nav_donation:hover h2 {
      color: #ffffff;
    }
    .div_nav_service_items h3:hover,
    .div_nav_contacts_items h3:hover {
      background-color: #000000;
      color: white;
      cursor: pointer;
    }
  }
}
