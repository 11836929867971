.div_page_spiritual_help {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  margin-bottom: 5vh;
}

.div_page_spiritual_help_1 {
  background-color: #ffffff;
  height: fit-content;
  text-align: center;
  padding: 7vh;
}

.div_page_spiritual_help h2 {
  font-size: 4vh;
  color: #ac9140;
}

.div_page_spiritual_help p {
  font-size: 3vh;
}

.div_page_spiritual_help_img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.div_page_spiritual_help_2 {
  height: fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  background-color: white;
}
#div_page_spiritual_help_scroll_down_btn {
  width: 8vh;
  height: 8vh;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  transition: color 0.25s;
}

#div_page_spiritual_help_scroll_down_btn:hover {
  cursor: pointer;
  color: #424242;
}

.div_page_spiritual_help_3 {
  margin-top: 30vh;
  width: 100%;
  height: fit-content;
}

.div_page_spiritual_help_3 h2 {
  text-align: center;
  color: #000000;
  margin: 3vh;
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_page_spiritual_help {
    margin-top: 8vh;
  }
}
