/* On screens that are 1000px or more*/
@media screen and (min-width: 1000px) {
  .div_page_news {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 0vh;
    margin-bottom: 40vh;
  }

  .div_page_news h2 {
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-size: 4vh;
    color: #ac9140;
    text-align: center;
  }

  .div_page_news_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .div_page_news_1_event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 1vh 0 1vh;
    width: fit-content;
  }

  .div_page_news_1_event h3 {
    font-size: 2.5vh;
  }

  .div_page_news_1_event img {
    margin: 0 auto 0 auto;
    padding: 0;
    width: 30vh;
    height: 30vh;
    margin-bottom: 1vh;
    object-fit: cover;
  }

  .div_page_news_2_event {
    display: flex;
    flex-direction: row;
    width: 70%;
    height: fit-content;
    margin: 0 auto 0 auto;
    padding-bottom: 3vh;
    border-bottom: 1px black solid;
  }

  .div_page_news_2_event img {
    margin: 0;
    margin-right: 1vh;
    padding: 0;
    width: 30vh;
    height: 30vh;
    margin-bottom: 1vh;
    object-fit: cover;
  }

  .div_page_news_2_event_txt {
    width: fit-content;
    height: fit-content;
  }

  .div_page_news_2_event_txt h2 {
    font-size: 3vh;
    text-align: left;
    margin: 0;
  }
  .div_page_news_2_event_txt h3 {
    margin-bottom: 0.5vh;
  }

  .div_page_news_2_event_txt p {
    margin: 0;
  }

  .div_page_news_2_event_txt_1 {
    margin-bottom: 1.5vh;
  }

  .div_page_news_2_event_txt_2 {
    margin: 0;
  }
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_page_news {
    margin-top: 10vh;
  }
}

/* On screens that are 1000px or less*/
@media screen and (max-width: 1000px) {
  .div_page_news {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 40vh;
  }

  .div_page_news h2 {
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 4vh;
    color: #ac9140;
    text-align: center;
  }

  .div_page_news_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .div_page_news_1_event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 2vh 0 2vh;
    width: fit-content;
  }

  .div_page_news_1_event h3 {
    font-size: 2.5vh;
  }

  .div_page_news_1_event img {
    margin: 0 auto 0 auto;
    padding: 0;
    width: 20vh;
    height: 20vh;
    margin-bottom: 1vh;
    object-fit: cover;
  }

  .div_page_news_2_event {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin: 0 auto 0 auto;
    padding-bottom: 3vh;
    border-bottom: 1px black solid;
  }

  .div_page_news_2_event img {
    margin: 0;
    margin-right: 1vh;
    padding: 0;
    width: 100%;
    height: 30vh;
    margin-bottom: 1vh;
    object-fit: contain;
  }

  .div_page_news_2_event_txt {
    width: fit-content;
    height: fit-content;
  }

  .div_page_news_2_event_txt h2 {
    font-size: 4vh;
    text-align: center;
    margin: 0;
  }
  .div_page_news_2_event_txt h3 {
    margin: 0 1vh 0.5vh 1vh;
  }

  .div_page_news_2_event_txt p {
    margin: 0 1vh 0 1vh;
  }

  .div_page_news_2_event_txt_1 {
    margin-bottom: 1.5vh;
  }

  .div_page_news_2_event_txt_2 {
    margin: 0;
  }
}
