.div_components {
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
}

.div_components_banner h1 {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #e6b31b;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-size: 6vw;
}

#div_components_scroll_down_btn {
  width: 6vh;
  height: 6vh;
  margin-left: auto;
  margin-right: auto;
  color: #e6b31b;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

#div_components_scroll_down_btn:hover {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.div_components_mission_txt h2:hover,
.div_components_choral_txt h2:hover,
.div_components_spiritual_help_txt h2:hover,
.div_components_volunteering_txt h2:hover {
  cursor: pointer;
}

/* On screens that are 1500px or more*/
@media screen and (min-width: 1500px) {
  .div_components_mission_img img,
  .div_components_choral_img img,
  .div_components_spiritual_help_img img,
  .div_components_volunteering_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }

  .div_components_mission_img:hover img,
  .div_components_choral_img:hover img,
  .div_components_spiritual_help_img:hover img,
  .div_components_volunteering_img:hover img {
    transform: scale(1.2);
    cursor: pointer;
  }
}

/* On screens that are 1000px or more*/
@media screen and (min-width: 1000px) {
  .div_components_mission,
  .div_components_choral,
  .div_components_spiritual_help,
  .div_components_volunteering {
    padding: 1vh 0 1vh 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .div_components_mission div,
  .div_components_choral div,
  .div_components_spiritual_help div,
  .div_components_volunteering div {
    flex-basis: 100%;
    flex: 1;
  }

  .div_components_mission_txt h2,
  .div_components_choral_txt h2,
  .div_components_spiritual_help_txt h2,
  .div_components_volunteering_txt h2 {
    font-size: 4vw;
    color: #ac9140;
  }

  .div_components_mission_img,
  .div_components_choral_img,
  .div_components_spiritual_help_img,
  .div_components_volunteering_img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .div_components_mission_txt,
  .div_components_choral_txt,
  .div_components_spiritual_help_txt,
  .div_components_volunteering_txt {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_components {
    margin-top: 8vh;
  }
}

/* On screens that are 1000px or less*/
@media screen and (max-width: 1000px) {
  .div_components {
    background-color: #ffffff;
  }
  .div_components_mission,
  .div_components_choral,
  .div_components_spiritual_help,
  .div_components_volunteering {
    display: flex;
    flex-direction: column;
  }

  .div_components_mission_txt h2,
  .div_components_choral_txt h2,
  .div_components_spiritual_help_txt h2,
  .div_components_volunteering_txt h2 {
    color: #ac9140;
  }

  .div_components_mission_img,
  .div_components_choral_img,
  .div_components_spiritual_help_img,
  .div_components_volunteering_img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .div_components_mission_txt,
  .div_components_choral_txt,
  .div_components_spiritual_help_txt,
  .div_components_volunteering_txt {
    width: fit-content;
    height: 100%;
    padding: 7vh;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #ffffff;
  }
}

/* On screens that are 1500px or more*/
@media screen and (min-width: 1500px) {
  .div_components p {
    font-size: 3vh;
    margin: 0.2vh;
  }
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_components h2 {
    font-size: 4vh;
    margin: 0.2vh;
  }

  .div_components p {
    font-size: 2.5vh;
    margin: 0.2vh;
  }

  .div_components_mission_img img,
  .div_components_choral_img img,
  .div_components_spiritual_help_img img,
  .div_components_volunteering_img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
