.div_page_youth {
  background-color: #fceecf;
  display: flex;
  flex-direction: column;
}

.div_page_youth_1 {
  background-color: #ffffff;
  height: fit-content;
  text-align: center;
  padding: 7vh;
}

.div_page_youth h2 {
  font-size: 4vh;
  color: #ac9140;
}

.div_page_youth p {
  font-size: 3vh;
}

#div_page_youth_img_youth {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 1vh;
}

.div_page_youth_2_img img,
.div_page_youth_3_img img,
.div_page_youth_4_img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* On screens that are 1700px or more*/
@media screen and (min-width: 1100px) {
  .div_page_youth {
    background-color: #fceecf;
  }

  .div_page_youth_2,
  .div_page_youth_3,
  .div_page_youth_4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: fit-content;
  }
}

/* Set width to fit content if content is oversize */
.div_page_youth_2_txt,
.div_page_youth_3_txt,
.div_page_youth_4_txt {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 0 1vw 0 1vw;
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_page_youth {
    margin-top: 8vh;
  }
}

/* On screens that are 1700px or less*/
@media screen and (max-width: 1100px) {
  .div_page_youth {
    background-color: #ffffff;
  }

  .div_page_youth_2,
  .div_page_youth_3,
  .div_page_youth_4 {
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: fit-content;
  }
}
