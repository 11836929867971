/* On screens that are 1500px or more*/
@media screen and (min-width: 1500px) {
  .div_nav {
    height: 80px;
    background-color: #ffffff;
    color: black;
  }

  .nav_desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  }

  .div_nav_home,
  .div_nav_mission,
  .div_nav_news,
  .div_nav_youth,
  .div_nav_choir,
  .div_nav_donation {
    padding: 5px;
    height: 100%;
  }

  .nav_desktop h2 {
    width: fit-content;
    padding-top: 2vh;
    margin: auto;
    color: black;
  }

  .div_nav_service,
  .div_nav_contacts {
    margin: 0;
    width: 20vh;
    padding-top: 5px;
    height: 100%;
    z-index: 2;
    background-position: -100% 0;
    background-size: 100% 200%;
    background-image: linear-gradient(
      to bottom,
      rgba(168, 54, 54, 0) 50%,
      #ac9140 50%
    );
    -webkit-transition: background-position 0.25s;
    -moz-transition: background-position 0.25s;
    transition: background-position 0.25s;
  }

  .div_nav_service h2,
  .div_nav_contacts h2 {
    margin: 0;
  }

  .div_nav_service_div,
  .div_nav_contacts_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .div_nav_service_div svg,
  .div_nav_contacts_div svg {
    font-size: 3vh;
    margin-top: auto;
  }
  .div_nav_service_items,
  .div_nav_contacts_items {
    display: flex;
    flex-direction: column;
  }

  .div_nav_service_items h3,
  .div_nav_contacts_items h3 {
    margin: 0 auto 0 auto;
    padding: 10px;
    text-align: center;
  }

  .div_nav_service.active,
  .div_nav_contacts.active {
    background-position: 0 -100%;
    color: #ffffff;
    height: fit-content;
  }

  .div_nav_service_div.active h2,
  .div_nav_service_div.active svg,
  .div_nav_contacts_div.active h2,
  .div_nav_contacts_div.active svg {
    color: #ffffff;
  }

  .div_nav_service.active .div_nav_service_items,
  .div_nav_contacts.active .div_nav_contacts_items {
    animation: fadeIn 1.7s;
    animation-fill-mode: forwards;
  }

  .div_nav_service.out .div_nav_service_items,
  .div_nav_contacts.out .div_nav_contacts_items {
    animation: fadeOut 1.7s;
    animation-fill-mode: forwards;
  }

  .div_nav_service_items h3:hover,
  .div_nav_contacts_items h3:hover {
    background-color: black;
    cursor: pointer;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
    }
    0% {
      visibility: visible;
      opacity: 1;
    }
  }

  .div_nav_home img {
    margin: 5%;
    background-size: 23vh 6vh;
    height: 6vh;
    width: 23vh;
  }

  .div_nav_home img:hover {
    cursor: pointer;
  }

  .div_nav_mission h2::after,
  .div_nav_news h2:after,
  .div_nav_choir h2:after,
  .div_nav_youth h2:after,
  .div_nav_donation h2:after {
    display: block;
    content: "";
    border-bottom: 5px #ac9140 solid;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform 250ms ease-in-out;
  }
  .div_nav_mission h2:hover:after,
  .div_nav_news h2:hover:after,
  .div_nav_choir h2:hover:after,
  .div_nav_youth h2:hover:after,
  .div_nav_donation h2:hover:after {
    transform: scaleX(1);
  }

  .nav_desktop h2:hover {
    cursor: pointer;
  }
}
