.div_form {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
}

.div_form form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.div_form input,
.div_form textarea,
.div_form button {
  margin: 1vh;
}

#div_form_name,
#div_form_email {
  height: 3vh;
  font-size: 2vh;
  border-style: none;
  border-bottom: 1px black solid;
  border-radius: 0%;
  padding: 1vh;
}

#div_form_message {
  height: 20vh;
  font-size: 2vh;
  padding: 1vh;
}

#div_form_btn {
  height: fit-content;
  padding: 1.5vh 0 1.5vh 0;
  font-size: 2.5vh;
  text-align: center;
  border: 1px black solid;
  background-color: black;
  color: white;
  transition: background-color 0.25s;
}

#div_form_btn:hover {
  cursor: pointer;
  background-color: #424242;
}

/* On screens that are 1000px or less*/
@media screen and (max-width: 1000px) {
  .div_form {
    width: 80vw;
  }
}
