.footer {
  margin-top: auto;
  height: fit-content;
  background-color: #ffffff;
}

.div_footer_social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1vh;
}

.div_footer_social img {
  margin: 0 3vh 0 3vh;
  width: 5vh;
  height: 5vh;
}

.div_footer_social img:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}

#copyRight {
  font-size: 1.3vh;
  text-align: center;
}
