.div_page_mission {
  display: flex;
  flex-direction: column;
}

.div_page_mission_1 {
  background-color: #ffffff;
  height: fit-content;
  text-align: center;
  padding: 7vh;
}

.div_page_mission h2 {
  font-size: 4vh;
  color: #ac9140;
}

.div_page_mission p {
  font-size: 3vh;
}

#div_page_mission_img_chair {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 1vh;
}

/* On screens that are 1100px or more*/
@media screen and (min-width: 1100px) {
  .div_page_mission {
    background-color: #fceecf;
  }

  .div_page_mission_2,
  .div_page_mission_3 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: fit-content;
  }
}

.div_page_mission_2_img img,
.div_page_mission_3_img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.div_page_mission_2_txt,
.div_page_mission_3_txt {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 0 1vw 0 1vw;
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_page_mission {
    margin-top: 8vh;
  }
}

/* On screens that are 1100px or less*/
@media screen and (max-width: 1100px) {
  .div_page_mission {
    background-color: #ffffff;
  }

  .div_page_mission_2,
  .div_page_mission_3 {
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: fit-content;
  }
}
