.div_page_contact {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  margin-bottom: 5vh;
}

.div_page_contact h2 {
  font-size: 4vh;
}

.div_page_contact p {
  font-size: 2vh;
}

.div_page_contact_1 {
  background-color: #ffffff;
  height: fit-content;
  text-align: center;
  padding: 7vh;
}

.div_page_choral_1_info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.div_page_choral_1_info div {
  margin: 1vh;
  padding: 0;
  width: fit-content;
  height: 100%;
  margin-bottom: 1vh;
  object-fit: contain;
}

.div_page_contact_2 {
  margin-top: 10vh;
  width: 100%;
  height: fit-content;
}

.div_page_contact_2 h2 {
  text-align: center;
  color: #000000;
  margin: 3vh;
}

/* On screens that are 1500px or less*/
@media screen and (max-width: 1500px) {
  .div_page_contact {
    margin-top: 8vh;
  }
}
